import purchaseSvg from "@/assets/svg/purchase.svg";
import { getRank } from '../rank'
let rank = getRank('purchaseManager')
export default {
  path: "/purchaseManager",
  redirect: "/purchase/index",
  meta: {
    icon: purchaseSvg,
    title: "采购管理",
    rank
  },
  children: [
    {
      path: "/purchase/plan",
      name: "PurchasePlan",
      component: () => import("@/views/CRM/purchaseManager/plan/index.vue"),
      meta: {
        title: "采购计划",
        keepAlive: false,
        roles: ['purchase_plan_view']
      }
    },
    {
      path: "/purchase/bill",
      name: "PurchaseBill",
      component: () => import("@/views/CRM/purchaseManager/purchaseBills/index.vue"),
      meta: {
        title: "采购单",
        keepAlive: false,
        roles: ['purchase_order_view']
      }
    },
    {
      path: "/purchase/audit",
      name: "PurchaseAudit",
      component: () => import("@/views/CRM/purchaseManager/audit/index.vue"),
      meta: {
        title: "采购审核",
        keepAlive: false,
        roles: ['purchase_audit']
      }
    }, {
      path: "/purchase/record",
      name: "PurchaseRecord",
      component: () => import("@/views/CRM/purchaseManager/record/index.vue"),
      meta: {
        title: "采购记录",
        keepAlive: false,
        roles: ['purchase_record']
      }
    }, {
      path: "/purchase/return",
      name: "PurchaseReturn",
      component: () => import("@/views/CRM/purchaseManager/return/index.vue"),
      meta: {
        title: "采购退货",
        keepAlive: false,
        roles: ['purchase_return']
      }
    },
    // {
    //   path: "/productPurchase/invoice",
    //   name: "Invoice",
    //   component: () => import("@/views/CRM/productPurchase/inAndOutStorge/index.vue"),
    //   meta: {
    //     title: "出入库单",
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: "/productPurchase/warning",
    //   name: "Warning",
    //   component: () => import("@/views/CRM/productPurchase/warning/index.vue"),
    //   meta: {
    //     title: "库存预警",
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: "/productPurchase/sell",
    //   name: "Sell",
    //   component: () => import("@/views/CRM/productPurchase/sell/index.vue"),
    //   meta: {
    //     title: "销售",
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: "/productPurchase/statement",
    //   name: "Statement2",
    //   component: () => import("@/views/CRM/productPurchase/statement/index.vue"),
    //   meta: {
    //     title: "报表",
    //     keepAlive: false
    //   }
    // },
  ]
} as RouteConfigsTable;