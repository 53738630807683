import stepSvg from "@/assets/svg/step.svg";
import { getRank } from '../rank'
let rank = getRank('step')
export default {
  path: "/step",
  meta: {
    icon: stepSvg,
    title: "工序管理",
    rank
  },
  children: [
    {
      path: "/step/index",
      name: "stepList",
      component: () => import("@/views/step/index.vue"),
      meta: {
        title: "工序列表",
        keepAlive: false,
        roles: ['step_list']
      }
    },
    {
      path: "/step/stepTemplate",
      name: "stepTemplate",
      component: () => import("@/views/step/stepTemplate.vue"),
      meta: {
        title: "工序模板",
        keepAlive: false,
        roles: ['step_template_view']
      }
    },
    {
      path: "/step/stepFixed/index",
      name: "stepFixed",
      component: () => import("@/views/step/stepFixed/index.vue"),
      meta: {
        title: "指定工序",
        keepAlive: false,
        roles: ['step_fixed', 'step_fixed_view', 'step_fixed_manager', 'step_fixed_export']
      }
    },
    {
      path: "/step/stepStyle/index",
      name: "stepStyle",
      component: () => import("@/views/step/stepStyle/index.vue"),
      meta: {
        title: "款式工序",
        keepAlive: false,
        roles:['style_step','style_step_list_view']
      }
    },
    {
      path: "/step/stepAudit/index",
      name: "stepAudit",
      component: () => import("@/views/step/stepAudit/index.vue"),
      meta: {
        title: "工序单价审核",
        keepAlive: false,
        roles:['step_audit','step_audit_view']
      }
    },
    {
      path: "/step/stepCheckAudit",
      name: "stepCheckAudit",
      component: () => import("@/views/step/stepCheckAudit/index.vue"),
      meta: {
        title: "工序完成审核",
        keepAlive: false,
        roles:['work_confirm']
      }
    },

  ]
} as RouteConfigsTable;
