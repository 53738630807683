import { $t } from "@/plugins/i18n";
import orderManager from "@/assets/svg/order.svg";
import statement from "@/assets/svg/statement.svg";
import newOrder from '@/assets/svg/newOrder.svg'
const Layout = () => import("@/layout/index.vue");
import { getRank } from '../rank'
let rank = getRank('order')
export default {
  path: "/order",
  redirect: "/order/index",
  meta: {
    icon: orderManager,
    title: "生产管理",
    rank,
    roles: ['order']
  },
  children: [
    {
      path: "/order/myOrder",
      name: "myOrder",
      component: () => import("@/views/order/myOrder.vue"),
      meta: {
        title: "大货单",
        keepAlive: false,
        roles: ["order_view"],
      }
    },
    {
      path: "/order/sampleOrder",
      name: "sampleOrder",
      component: () => import("@/views/order/sampleOrder.vue"),
      meta: {
        title: "样板单",
        keepAlive: false,
        roles: ["order_view"]
      }
    },
    {
      path: "/order/statement",
      name: "statement",
      component: () => import("@/views/order/statement/index.vue"),
      meta: {
        title: "报表",
        keepAlive: false,
        roles: ["order_report_profit", 'order_report_cut'],
        showParent: true,
        // showLink: false,
      }
    },
    {
      path: "/order/createOrder",
      name: "OrderManager",
      component: () => import("@/views/order/createOrder.vue"),
      meta: {
        title: "下单",
        keepAlive: false,
        showLink: false,
        showParent: true
      }
    },
    {
      path: "/order/orderDetail",
      name: "OrderDetail",
      component: () => import("@/views/order/orderDetail.vue"),
      meta: {
        title: "订单详细",
        keepAlive: false,
        showLink: false,
        showParent: true
      }
    },
    {
      path: "/order/stepProgress",
      name: "StepProgress",
      component: () => import("@/views/order/stepPropress.vue"),
      meta: {
        title: "工序进度明细",
        keepAlive: false,
        showLink: false,
        showParent: true
      }
    }
  ]
};
